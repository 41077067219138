// 获取常用时间
import dayjs from 'dayjs'

export const LAST_7_DAYS = [
    dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
]

export const LAST_30_DAYS = [
    dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
    dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
]

export const getNone = []

export const getYears = [
    dayjs().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('year').format('YYYY-MM-DD HH:mm:ss'),
]

export const formatDate = (time, format) => (time ? dayjs(time).format(format) : '')

export const formatDateNull = (time, format) => (time != '' ? dayjs(time).format(format) : null)
