import Vue from 'vue'
import { formatDate } from '@/utils/date'
// dictValue
Vue.filter('mapFormatter', function (val, map) {
    return (map.filter((v) => v.dictValue == val).pop() || {}).dictDescribe || '-'
})
// value label
Vue.filter('typeFormatter', function (val, map, no = '_') {
    return (map.filter((v) => v.value == val).pop() || {}).label || no
})
// value text
Vue.filter('affairsOpenList', function (val, map) {
    return (map.filter((v) => v.value == val).pop() || {}).text || '-'
})
// 时间处理
Vue.filter('formatDate', function (val, type) {
    return formatDate(val, type)
})
