import request from '@/utils/request'

const configApi = '/api/login'

// 获取公共key
export function getPublicKey (params) {
    return request({
        url: `${configApi}/login/loginUser/getPublicKey`,
        method: 'get',
        params,
    })
}

// 生成验证码
export function getCode (params) {
    return request({
        url: `${configApi}/login/loginUser/getCode`,
        responseType: 'blob',
        method: 'get',
        params,
    })
}

// 获取用户信息
export function getTokenInfo () {
    return request({
        url: `${configApi}/login/loginUser/getTokenInfo`,
        method: 'get',
    })
}

// 查询身份
export function queryIdentity () {
    return request({
        url: `${configApi}/login/loginUser/queryIdentity`,
        method: 'get',
    })
}

// 切换身份
export function switchIdentity (params) {
    return request({
        url: `${configApi}/login/loginUser/switchIdentity`,
        method: 'get',
        params,
    })
}

// 根据账号密码登录
export function loginByAccount (data) {
    return request({
        url: `${configApi}/login/loginUser/loginByAccount`,
        method: 'post',
        data,
    })
}
// 通过手机号获取短信验证码
export function getShortCode (params) {
    return request({
        url: `${configApi}/login/loginUser/getShortCode`,
        method: 'get',
        params,
    })
}

// 党员通过手机号和验证码登陆
export function loginPartyByPhone (data) {
    return request({
        url: `${configApi}/login/loginUser/loginPartyByPhone`,
        method: 'post',
        data,
    })
}
//浙政钉授权码登录
export function loginByZzdAuthCode (params) {
    return request({
        url: `${configApi}/login/loginUser/loginByZzdAuthCode`,
        method: 'get',
        params,
    })
}
// 绑定党员浙征订id
export function bindingUser (data) {
    return request({
        url: `${configApi}/login/loginUser/bindingUser`,
        method: 'post',
        data,
    })
}
