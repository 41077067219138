import router from './router'
import store from '@/store'
import { JSEncrypt } from 'jsencrypt'
import * as API from '@/api/login'
// import { setToken } from '@/utils/auth'

const whiteListRouters = store.getters.whiteListRouters

router.beforeEach(async (to, from, next) => {
    document.title = to.meta.title
    // setToken('3f1bac2ab1a740e89414b14c6acf74ee')
    const token = store.getters.token

    if (token) {
        if (to.path === '/403') {
            next({ path: '/' })
        }
        // 对菜单id进行加密处理
        if (to && to.meta && to.meta.id) {
            const getPublicKey = await API.getPublicKey()
            const encrypt = new JSEncrypt()
            encrypt.setPublicKey(getPublicKey.data.publicKey)
            const encryptMenuId = encrypt.encrypt(to.meta.id)
            sessionStorage.setItem('acitveMenuId', encodeURIComponent(encryptMenuId))
            sessionStorage.setItem('privateKeyKey', getPublicKey.data.privateKeyKey)
        }

        // 动态路由注册
        const hasGetUserInfo = store.getters['userInfo']
        const routers = store.getters['routers']
        if (hasGetUserInfo && Object.keys(hasGetUserInfo).length > 0 && routers.length > 0) {
            next()
        } else {
            await store.dispatch('user/getUserInfo')
            const accessRoutes = await store.dispatch('permission/initRoutes')
            if (accessRoutes && accessRoutes.length === 0) {
                // await MessagePlugin.error('您暂无权限，请联系管理员配置角色')
                await store.dispatch('user/logout')
                next(`/403`)
            } else {
                // router.addRoutes(accessRoutes)
                accessRoutes.forEach((i) => {
                    router.addRoute(i)
                })
                next({ ...to, replace: true })
            }
        }
        // 解决党费缴纳凭证上传新增时，通过专有钉返回按钮，重复提交，通过拦截守卫拦截跳转
        if (to.name === 'paymentUploadDetail' && from.name === 'paymentUploadResult') {
            next({ name: 'paymentUpload' })
        }
        next()
    } else {
        console.log('whiteListRouters', whiteListRouters)
        // /* 白名单路由列表 */
        if (whiteListRouters.indexOf(to.path) !== -1) {
            console.log('to.path', to.path)
            next()
        } else {
            next(`/Signature`)
        }
        // next(`/404`)
    }
})
