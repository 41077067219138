import request from '@/utils/request'

const configApi = '/api/orgConstruct'

// 根据组织id查询组织-党组织信息
export function queryOrgInfoByOrgId(params) {
    return request({
        url: `${configApi}/construct/org/queryOrgInfoByOrgId`,
        method: 'get',
        params,
    })
}

// 领导班子
export function queryLeadershipRanksList(params) {
    return request({
        url: `${configApi}/construct/party/queryLeadershipRanksList`,
        method: 'get',
        params,
    })
}

// 字典查询
export function dictionaryLookUp(params) {
    return request({
        url: `${configApi}/construct/sysDictionaries/getDataByKey`,
        method: 'get',
        params,
    })
}

// 查询职务列表
export function queryDutiesList(params) {
    return request({
        url: `${configApi}/construct/duties/queryDutiesList`,
        method: 'get',
        params,
    })
}

// 先进党组织-组织荣誉-根据组织id查询历年荣誉
export function getDetail(params) {
    return request({
        url: `${configApi}/construct/advanced/getHonorList`,
        method: 'get',
        params,
    })
}

// 先进党组织列表
export function getAdvancedList(params) {
    return request({
        url: `${configApi}/construct/advanced/getList`,
        method: 'get',
        params,
    })
}

// 查询组织树数据
export function queryOrgTree(params) {
    return request({
        url: `${configApi}/construct/org/queryOrgTree`,
        method: 'get',
        params,
    })
}
// 详情
// export function getDetail(params) {
//     return request({
//         url: `${configApi}/construct/advanced/getDetail`,
//         method: 'get',
//         params,
//     })
// }

// 附件下载
export function downloadFile(params) {
    return request({
        url: `${configApi}/file/downloadFile`,
        method: 'get',
        responseType: 'blob',
        params,
    })
}

// 党员列表
export function queryUserList(params) {
    return request({
        url: `${configApi}/construct/party/queryUserList`,
        method: 'get',
        params,
    })
}
// 根据当前登录人的手机号获取当前登陆人的党员信息
export function queryPartyInfoByToken(params) {
    return request({
        url: `${configApi}/construct/party/queryPartyInfoByToken`,
        method: 'get',
        params,
    })
}

// 阵地列表
export function getRootList(params) {
    return request({
        url: `${configApi}/construct/position/getList`,
        method: 'GET',
        params,
    })
}

// 列表封面
export function downloadFileView(params) {
    return request({
        url: `${configApi}/file/downloadFileByCode`,
        method: 'GET',
        responseType: 'blob',
        params,
    })
}

// 阵地详情
export function getPositionDetail(params) {
    return request({
        url: `${configApi}/construct/position/getDetail`,
        method: 'GET',
        params,
    })
}

export function getCode(params) {
    return request({
        url: `${configApi}/file/getCode`,
        method: 'GET',
        params,
    })
}

export function downloadCompressFileByCode(params) {
    return request({
        url: `${configApi}/file/downloadCompressFileByCode`,
        method: 'GET',
        responseType: 'blob',
        params,
    })
}

// 消防换届列表
export function getFireTransitionList(params) {
    return request({
        url: `${configApi}/construct/fireTransition/getList`,
        method: 'GET',
        params,
    })
}

// 查询消防换届详情
export function getFireDetail(params) {
    return request({
        url: `${configApi}/construct/fireTransition/getDetail`,
        method: 'GET',
        params,
    })
}

// 消防换届请示批复
export function fireTransitionAudit(data) {
    return request({
        url: `${configApi}/construct/fireTransition/audit`,
        method: 'post',
        data,
    })
}

// 党费列表
export function getPartyMemberShipDuesList(params) {
    return request({
        url: `${configApi}/construct/partyMembership/getPartyMemberShipDuesList`,
        method: 'GET',
        params,
    })
}

// 党费详情
export function paymentUploadDetail(params) {
    return request({
        url: `${configApi}/construct/partyMembership/getDetail`,
        method: 'GET',
        params,
    })
}

// 提交凭证
export function paymentUploadInsert(data) {
    return request({
        url: `${configApi}/construct/partyMembership/insert`,
        method: 'post',
        data,
        timeout: 15000,
    })
}
