import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 存放固定的路由
export const defaultRouterList = [
    {
        path: '/login',
        name: 'login',
        meta: { title: '登录', isHiddenBar: true, hidden: true },
        component: () => import('@/views/login/index.vue'),
    },
    {
        path: '/phoneLogin',
        name: 'phoneLogin',
        meta: { title: '手机号登录', isHiddenBar: true, hidden: true },
        component: () => import('@/views/login/phoneLogin.vue'),
    },
    {
        path: '/clickLogin',
        name: 'clickLogin',
        meta: { title: '单点失败登录', isHiddenBar: true, hidden: true },
        component: () => import('@/views/login/clickLogin.vue'),
    },
    {
        path: '/Signature',
        name: 'Signature',
        meta: { title: '免登页面', isHiddenBar: true, hidden: true },
        component: () => import('@/views/login/Signature.vue'),
    },
    {
        path: '/403',
        name: '403',
        meta: { title: '无权限', hidden: true },
        component: () => import('@/views/result/403'),
    },
    {
        path: '/404',
        name: '404',
        meta: { title: '页面不存在', hidden: true },
        component: () => import('@/views/result/404'),
    },
    {
        path: '/notToken',
        name: 'notToken',
        meta: { title: '页面不存在', hidden: true },
        component: () => import('@/views/result/notToken'),
    },
]

const createRouter = () =>
    new Router({
        // mode: 'history',
        scrollBehavior: () => ({ y: 0 }),
        routes: defaultRouterList,
    })

const router = createRouter()

export function resetRouter () {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher //
}

export default router
