import domtoimage from 'dom-to-image'
export default {
    install(Vue) {
        Vue.prototype.$getImg = function (dom, imgName) {
            domtoimage
                .toJpeg(document.querySelector(dom), { quality: 0.95 })
                .then(function (dataUrl) {
                    var link = document.createElement('a')
                    link.download = imgName
                    link.href = dataUrl
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                })
        }
    }
}
