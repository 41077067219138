import request from '@/utils/request'

const configApi = '/api/orgConstruct'

// 下载文件
export function downloadFileByCode(params) {
    return request({
        url: `${configApi}/file/downloadFileByCode`,
        method: 'get',
        responseType: 'blob',
        params,
    })
}
