import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import './permission'
import Tag from '@/components/Tag/index.vue' //自制Tag
import dom2img from '@/utils/dom2img'
import '@/filters' // 过滤
import Vconsole from 'vconsole'

let vConsole = new Vconsole();
Vue.use(Vant)
Vue.use(dom2img)
Vue.use(vConsole)
Vue.component('tag', Tag)
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
