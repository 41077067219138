<template>
    <van-tabbar
        v-model="active"
        route
        safe-area-inset-bottom
        active-color="#EE1C25"
        inactive-color="rgba(0, 0, 0, 0.60)"
    >
        <van-tabbar-item replace v-for="item in list" :key="item.id" :to="item.path">
            <span>{{ item.title }}</span>
            <template #icon="props">
                <!--pc端菜单管理里面移动端icon设置，通过遍历icon获取图片地址-->
                <img class="icon" :src="getIcon(item.meta.icon, props.active)" />
            </template>
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'tabBar',
    data() {
        return {
            active: 0,
        }
    },
    computed: {
        ...mapGetters({ router: 'routers' }),
        list() {
            let arr = this.router.filter((item) => item.id && item.level === 1 && !item.hidden)
            console.log('arr>>>', arr)
            return arr
        },
    },
    methods: {
        getIcon(src, isActive = false) {
            return require(`@/assets/icons/tabBar/icon_bar_${src}${isActive ? '_active' : ''}.png`)
        },
    },
}
</script>

<style scoped>
.icon {
    width: 20px;
    height: 20px;
}
</style>
