<template>
    <div :class="`${type} ${size} ${round ? 'roundTag' : ''} ${className}`">
        <slot v-if="$slots.value" name="value"></slot>
        <span v-else>
            {{ value }}
        </span>
    </div>
</template>

<script lang="jsx">
export default {
    props: {
        value: String,
        size: {
            type: String,
            default: 'mini',
        },
        type: {
            type: String,
            default: 'danger',
        },
        round: {
            type: Boolean,
            default: false,
        },
        className: {
            type: String,
            default: '',
        },
    },
    components: {},
    data() {
        return {}
    },
    mounted() {
        //
    },
    methods: {},
}
</script>

<style scoped lang="less">
.mini,
.small {
    font-weight: 400;
    font-style: normal;
}
.mini {
    font-size: 12px;
}

.danger,
.primary,
.warring,
.success,
.default {
    border-radius: 2px;
    padding: 2px 8px;
}
.success {
    color: #07c160;
    background: rgba(7, 193, 96, 0.1);
}
.danger {
    background: rgba(245, 67, 67, 0.1);
    color: #f54343;
}
.primary {
    background: rgba(8, 148, 250, 0.1);
    color: #0894fa;
}
.warring {
    background: rgba(250, 180, 64, 0.1);
    color: #fab440;
}
.default {
    background: var(--gray-gray-2, #eee);
    color: var(--text-icon-font-gy-260, rgba(0, 0, 0, 0.6));
}

.roundTag {
    border-radius: 25px;
}
</style>
