import * as API from '@/api/auth/bSysMenu'
import { defaultRouterList } from '@/router'

function filterAsyncRouter (asyncRouterMap) {
    // 遍历后台传来的路由字符串，转换为组件对象
    try {
        const accessedRouters = asyncRouterMap.filter((route) => {
            let meta = {
                id: route.id,
                title: route.title,
                icon: route.ydIcon,
            }
            route.meta = meta
            if (route && route.ydPath) {
                route.path = route.ydPath
            }
            if (route.ydComponent) {
                if (route.ydComponent === 'Layout') {
                    route.component = 'Layout'
                } else {
                    route.component = loadView(route.ydComponent)
                }
            } else {
                route.component = null
            }

            if (route.children && route.children.length) {
                route.children = filterAsyncRouter(route.children)
            }
            return true
        })
        return accessedRouters
    } catch (e) {
        console.log(e)
    }
}

const getDefaultState = () => {
    return {
        whiteListRouters: ['/404', '/403', '/notToken', '/login', '/phoneLogin', '/clickLogin', '/Signature'],
        routers: [],
    }
}

const state = getDefaultState()

const mutations = {
    setRouters: (state, routers) => {
        state.routers = routers
    },
}

const actions = {
    async initRoutes ({ commit }) {
        const menuData = await API.selectRoleMenuTree({
            type: '1',
        })

        let data = [...menuData.data]

        if (data && data.length > 0) {
            let tabBarList = data.filter((item) => item.name === 'partyHome')
            // 一级目录设置icon
            data.map((item) => {
                if (item.ydPath && item.children.length > 0) {
                    item.redirect = `${item.ydPath}/${item.children[0].ydPath}`
                } else {
                    item.redirect = ''
                }
                return item
            })
            // 输入斜杠默认跳转到第一个
            data.unshift({ path: '/', redirect: tabBarList[0].ydPath, meta: { hidden: true } })
            data.push({ path: '*', redirect: '/404', hidden: true, meta: { hidden: true } })
        }
        const asyncRouter = data
        console.log('asyncRouter', asyncRouter)
        // 进行封装common
        return new Promise((resolve) => {
            const accessedRoutes = filterAsyncRouter(asyncRouter)
            commit('setRouters', defaultRouterList.concat(accessedRoutes))
            resolve(accessedRoutes)
        })
    },
}

export const loadView = (view) => {
    return (resolve) => require(['@/views' + view], resolve)
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
