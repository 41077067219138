import { getToken, setToken, removeToken } from '@/utils/auth'
import * as API from '@/api/login'

import { queryPartyInfoByToken } from '@/api/orgConstruct/constructOrg'
import { downloadFileByCode } from '@/api/file'
import { JSEncrypt } from 'jsencrypt'

const getDefaultState = () => {
    return {
        token: getToken(),
        userInfo: {},
        rewardsPunishList: [],
    }
}

const state = getDefaultState()
// 调用vuex mutations 方法
// store.dispatch('user/SET_NAME',值)
const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    setToken (state, token) {
        setToken(token)
        state.token = token
    },
    setUserInfo (state, userInfo) {
        state.userInfo = userInfo
    },
    setList (state, arr) {
        state.rewardsPunishList = arr
    },
}
// 调用vuex action方法
// store.dispatch('user/resetToken').then(() => { })
const actions = {
    login ({ commit }, userInfo) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            // 登录请求流程
            const { account, password, verifyCode } = userInfo
            const getPublicKey = await API.getPublicKey()
            const encrypt = new JSEncrypt()
            encrypt.setPublicKey(getPublicKey.data.publicKey)
            const encryptPwd = encrypt.encrypt(password)

            const data = {
                account,
                password: encodeURIComponent(encryptPwd),
                privateKeyKey: getPublicKey.data.privateKeyKey,
                verifyCode,
            }
            const loginData = await API.loginByAccount(data)
            if (loginData.code === 1) {
                commit('setToken', loginData.data)
            } else {
                removeToken()
                commit('setUserInfo', {})
            }
            resolve(loginData)
        })
    },
    loginPartyByPhone ({ commit }, userInfo) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            // 登录请求流程
            const { userPhone, verifyCode } = userInfo
            const getPublicKey = await API.getPublicKey()
            const encrypt = new JSEncrypt()
            encrypt.setPublicKey(getPublicKey.data.publicKey)
            const data = {
                userPhone,
                verifyCode,
            }
            const loginData = await API.loginPartyByPhone(data)
            if (loginData.code === 1) {
                commit('setToken', loginData.data)
            } else {
                removeToken()
                commit('setUserInfo', {})
            }
            resolve(loginData)
        })
    },
    loginByZzdAuthCode ({ commit }, userInfo) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            // 登录请求流程
            const { authCode } = userInfo
            const getPublicKey = await API.getPublicKey()
            const encrypt = new JSEncrypt()
            encrypt.setPublicKey(getPublicKey.data.publicKey)
            const data = {
                authCode: authCode
            }
            const loginData = await API.loginByZzdAuthCode(data)
            if (loginData.code === 1) {
                if (loginData.data.token) {
                    commit('setToken', loginData.data.token)
                }
            } else {
                removeToken()
                commit('setUserInfo', {})
            }
            resolve(loginData)
        })
    },
    bindingUser ({ commit }, userInfo) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            // 登录请求流程
            const { userPhone, userName, zzdId } = userInfo
            const getPublicKey = await API.getPublicKey()
            const encrypt = new JSEncrypt()
            encrypt.setPublicKey(getPublicKey.data.publicKey)
            const data = {
                userPhone,
                userName,
                zzdId
            }
            const loginData = await API.bindingUser(data)
            if (loginData.code === 1) {
                commit('setToken', loginData.data)
            } else {
                removeToken()
                commit('setUserInfo', {})
            }
            resolve(loginData)
        })
    },
    getUserInfo ({ commit }) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            let res = await API.getTokenInfo()
            let info = await queryPartyInfoByToken()
            info = info.data
            if (info && info.imageFile && info.imageFile.id) {
                let image = await downloadFileByCode({
                    code: info.imageFile.code,
                    id: info.imageFile.id,
                })
                const url = window.URL.createObjectURL(new Blob([image]))
                res.data.avatar = url
            }

            // console.log('info', info)
            resolve(res)
            commit('setUserInfo', res.data)
        })
    },

    // user logout
    // logout({ commit, state }) {
    //     return new Promise((resolve, reject) => {
    //         logout(state.token)
    //             .then(() => {
    //                 removeToken() // must remove  token  first
    //                 resetRouter()
    //                 commit('RESET_STATE')
    //                 resolve()
    //             })
    //             .catch((error) => {
    //                 reject(error)
    //             })
    //     })
    // },

    // remove token
    resetToken ({ commit }) {
        return new Promise((resolve) => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
