import axios from 'axios'
import { Notify } from 'vant'
// import store from '@/store'
import { getToken } from '@/utils/auth'
const SUCCESS_CODE = 1

const API_HOST = 'http://223.4.71.139:443' // 如果是mock模式 就不配置host 会走本地Mock拦截
// create an axios instance
const service = axios.create({
    baseURL: API_HOST, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    // timeout: 5000, // request timeout
    timeout: 60000, // request timeout
    withCredentials: true
})

service.interceptors.request.use(async (config) => {
    config.headers.token = getToken()
    const privateKeyKey = sessionStorage.getItem('privateKeyKey')
    const menuId = sessionStorage.getItem('acitveMenuId')
    if (config.method === 'get') {
        config.params = {
            privateKeyKey,
            menuId,
            ...config.params
        }
    } else if (config.method === 'post' || config.method === 'put' || config.method === 'delete') {
        if (config.data instanceof FormData) {
            config.data.append('menuId', menuId)
            config.data.append('privateKeyKey', privateKeyKey)
        } else {
            config.data = {
                privateKeyKey,
                menuId,
                ...config.data
            }
        }
    }
    return config
})

service.interceptors.response.use(
    // eslint-disable-next-line consistent-return
    (response) => {
        if (response.status === 200) {
            const { data } = response
            if (data.code && data.code !== SUCCESS_CODE) {
                Notify({ type: 'danger', message: data.codeMessage })
                // return data
            }
            return data
            // return Promise.reject(data)
        }
        return Promise.reject(response.data)
    },
    (e) => {
        Promise.reject(e)
        //   Notify.error(e.response.data.message || e.response.data.codeMessage).then(() => {})
        Notify({
            type: 'danger',
            message: e.message || e.response.data.codeMessage
        })
    }
)

export default service
