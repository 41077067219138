<template>
    <div id="app" :class="$route.meta.isHiddenBar ? 'isHiddenBar' : ''">
        <router-view class="main" :style="$route.meta.isHiddenBar ? { paddingBottom: '0' } : ''" />
        <TabBar />
    </div>
</template>

<script>
// import { setToken } from '@/utils/auth'

import TabBar from './components/tabbar'
export default {
    components: {
        TabBar,
    },
    mounted() {
        // setToken('1e1a6d02168f4a07ba4bf8ef3c3511bb')
    },
}
</script>

<style lang="less">
/* 增加以下样式 */
html,
body {
}
#app {
    font-size: 14px;
    // 第一个50px为底部导航栏高度，第二个50px为顶部钉钉的导航栏高度
    height: calc(100vh - 50px - 50px);
    color: rgba(0, 0, 0, 0.9);
    .main {
        height: 100%;
        overflow: auto;
        padding-bottom: 16px;
        //overflow: hidden;
        box-sizing: border-box;
        background: #f5f5f5;
    }
}

.flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.flexSb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.flexSa {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}
.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
.van-tab--active {
    color: #ee1c25;
}
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>

<style lang="less" scoped>
.isHiddenBar {
    height: 100vh !important;
}
</style>
