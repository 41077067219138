import request from '@/utils/request'

const configApi = '/api/auth'

// 菜单管理-获取登录用户的菜单
export function selectRoleMenuTree(params) {
    return request({
        url: `${configApi}/bSysMenu/selectRoleMenuTree`,
        method: 'get',
        params,
    })
}

// 菜单管理-获取导航栏菜单
export function getNavigationBar() {
    return request({
        url: `${configApi}/bSysMenu/getNavigationBar`,
        method: 'get',
    })
}

// 菜单管理-获取常用菜单
export function selectRoleMenuTreeByPopular() {
    return request({
        url: `${configApi}/bSysMenu/selectRoleMenuTreeByPopular`,
        method: 'get',
    })
}

// 菜单管理-新增常用菜单
export function addPopularRoleMenu(data) {
    return request({
        url: `${configApi}/bSysMenu/addPopularRoleMenu`,
        method: 'post',
        data,
    })
}

// 菜单管理-删除常用菜单
export function deletePopularRoleMenu(data) {
    return request({
        url: `${configApi}/bSysMenu/deletePopularRoleMenu`,
        method: 'delete',
        data,
    })
}

// 菜单管理-菜单拖拽排序
export function popularMenuDragSort(data) {
    return request({
        url: `${configApi}/bSysMenu/popularMenuDragSort`,
        method: 'post',
        data,
    })
}
